import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory() {
  const http = inject(HttpClient);

  return new TranslateHttpLoader(http, './i18n/', '.json');
}
