import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  booleanAttribute,
  effect,
  inject,
  input,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  FormControlDirective,
  FormControlName,
  NgModel,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { FieldErrorStateMatcher, injectNgControl } from '@rp/utils';
import { NoopValueAccessorDirective } from '@rp/shared/directives';
import { filter } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

import { IconComponent } from '../icon';

@Component({
  selector: 'rp-date-picker',
  standalone: true,
  templateUrl: './date-picker.component.html',
  hostDirectives: [NoopValueAccessorDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    ReactiveFormsModule,
    IconComponent,
    TranslateModule,
  ],
  providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } }],
})
export class DatePickerComponent implements OnInit {
  label = input<string>('');
  hint = input<string>('');
  placeholder = input<string>('');
  placeholderStart = input<string>('');
  placeholderEnd = input<string>('');
  startDateKey = input<string>('startDate');
  endDateKey = input<string>('endDate');
  matcher = input<FieldErrorStateMatcher>();
  isDisabled = input(false, {
    transform: booleanAttribute,
  });
  isRequired = input(false, {
    transform: booleanAttribute,
  });

  hasRange = false;

  ngControl: FormControlDirective | FormControlName | NgModel = injectNgControl();
  startControl = new FormControl<Date | null>(null);
  endControl = new FormControl<Date | null>(null);

  private _destroyRef = inject(DestroyRef);

  constructor() {
    effect(() => {
      const { control } = this.ngControl;

      if (this.isDisabled()) {
        control.disable();
        this.startControl.disable();
        this.endControl.disable();
      } else {
        control.enable();
        this.startControl.enable();
        this.endControl.enable();
      }

      if (this.isRequired() || this.ngControl.control.errors?.['required']) {
        control.setValidators(Validators.required);
        this.startControl.setValidators(Validators.required);
        this.endControl.setValidators(Validators.required);
      } else {
        control.removeValidators(Validators.required);
        this.startControl.removeValidators(Validators.required);
        this.endControl.removeValidators(Validators.required);
      }
    });
  }

  ngOnInit(): void {
    this._setRangeControlsValues();
    this._listenRangeControlsChanges();
  }

  private _setRangeControlsValues(): void {
    this.hasRange =
      !!this.ngControl.control.value && !!Object.keys(this.ngControl.control.value).length;
    if (this.hasRange && this.startDateKey() && this.endDateKey()) {
      this.startControl.setValue(this.ngControl.value?.[this.startDateKey()]);
      this.endControl.setValue(this.ngControl.value?.[this.endDateKey()]);
    }
  }

  private _listenRangeControlsChanges(): void {
    this.startControl.valueChanges
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter(() => this.hasRange),
      )
      .subscribe(value => {
        this.ngControl.control.setValue({
          ...this.ngControl.control.value,
          [this.startDateKey()]: value,
        });
      });

    this.endControl.valueChanges
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter(() => this.hasRange),
      )
      .subscribe(value => {
        this.ngControl.control.setValue({
          ...this.ngControl.control.value,
          [this.endDateKey()]: value,
        });
      });
  }
}
