import { GlobalPositionStrategy, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import {
  DestroyRef,
  EnvironmentInjector,
  inject,
  Injectable,
  InjectionToken,
  Injector,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { Subject, concatMap } from 'rxjs';

import { DialogRef } from '../models';

export const DIALOG_DATA = new InjectionToken<never>('DIALOG_DATA');

/**
 * Config for custom ref settings
 * Extend when necessary
 */
interface CustomOverlayProps {
  hasBackdrop?: boolean;
  right?: string;
  bottom?: string;
  centerHorizontally?: boolean;
  overlayBackdropClass?: string;
  centerVertically?: boolean;
  panelClass?: string;
  isOutOfQueue?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private _dialogsQueueSubject = new Subject<{
    portal: ComponentPortal<unknown>;
    overlayRef: OverlayRef;
    dialogRef: DialogRef;
  }>();

  private _overlay = inject(Overlay);
  private _injector = inject(Injector);
  private _destroyRef = inject(DestroyRef);

  constructor() {
    this._dialogsQueueSubject
      .pipe(
        concatMap(({ portal, overlayRef, dialogRef }) => {
          overlayRef.attach(portal);

          return dialogRef.afterClosed();
        }),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe();
  }

  /**
   * Open a custom component in an overlay
   */
  open<T, V>(
    component: ComponentType<T>,
    config?: V,
    props?: CustomOverlayProps,
    injector?: EnvironmentInjector,
  ): DialogRef {
    const overlayRef = this._overlay.create({
      positionStrategy: this._positionStrategy,
      hasBackdrop: true,
      backdropClass: props?.overlayBackdropClass || 'rp-overlay-backdrop',
      panelClass: props?.panelClass || 'rp-overlay-panel',
      scrollStrategy: this._overlay.scrollStrategies.block(),
    });

    return this._attachDialogRef(component, overlayRef, config, props?.isOutOfQueue, injector);
  }

  /**
   * Open with custom ref
   * @param component
   * @param overlayRef
   * @param config
   */
  openWithCustomRef<T, V>(
    component: ComponentType<T>,
    overlayRef: OverlayRef,
    config?: V,
  ): DialogRef {
    return this._attachDialogRef(component, overlayRef, config);
  }

  /**
   * Create custom ref
   * @param props
   */
  createCustomRef(props: CustomOverlayProps): OverlayRef {
    const positionStrategy = this._overlay.position().global();

    if (props.centerHorizontally) {
      positionStrategy.centerHorizontally();
    }

    if (props.centerVertically) {
      positionStrategy.centerVertically();
    }

    if (props.bottom) {
      positionStrategy.bottom(props.bottom);
    }

    if (props.right) {
      positionStrategy.right(props.right);
    }

    return this._overlay.create({
      disposeOnNavigation: true,
      maxWidth: '100%',
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy,
      hasBackdrop: props.hasBackdrop ?? true,
      panelClass: props.panelClass || 'rp-overlay-panel',
      backdropClass: 'rp-overlay-backdrop',
    });
  }

  private _attachDialogRef<T, V>(
    component: ComponentType<T>,
    overlayRef: OverlayRef,
    config?: V,
    isOutOfQueue?: boolean,
    injector?: EnvironmentInjector,
  ): DialogRef {
    // Create dialogRef to return
    const dialogRef = new DialogRef(overlayRef);

    // Create injector to be able to reference the DialogRef from within the component
    const portalInjector = Injector.create({
      parent: injector || this._injector,
      providers: [
        { provide: DialogRef, useValue: dialogRef },
        { provide: DIALOG_DATA, useValue: config },
      ],
    });

    // Attach component portal to the overlay
    const portal = new ComponentPortal(component, null, portalInjector);

    if (isOutOfQueue) {
      overlayRef.attach(portal);
    } else {
      this._dialogsQueueSubject.next({ portal, overlayRef, dialogRef });
    }

    return dialogRef;
  }

  /**
   * Get _positionStrategy
   */
  private get _positionStrategy(): GlobalPositionStrategy {
    return this._overlay.position().global().centerHorizontally().centerVertically();
  }
}
