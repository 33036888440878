<div class="rp-radio">
  <mat-radio-group
    class="rp-radio__group"
    [required]="required()"
    [labelPosition]="labelPosition()"
    [name]="groupName()"
    [formControl]="ngControl.control"
  >
    @for (radioButton of radioButtons(); track $index) {
      <mat-radio-button
        class="rp-radio__button"
        [ngClass]="{
          'no-label': !radioButton.label,
          invalid: ngControl.control.touched && ngControl.control.invalid,
        }"
        disableRipple="true"
        [disabled]="radioButton.disabled"
        [checked]="radioButton.checked"
        [value]="radioButton.value"
      >
        {{ radioButton.label }}

        @if (radioButton.iconName || iconName()) {
          <rp-icon class="rp-radio__icon" [iconName]="radioButton.iconName || iconName()" size="xs" />
        }
      </mat-radio-button>
    }
  </mat-radio-group>
</div>
