import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  booleanAttribute,
  computed,
  inject,
  input,
  signal,
} from '@angular/core';
import { NgFor } from '@angular/common';
import {
  FormControl,
  FormControlDirective,
  FormControlName,
  FormControlStatus,
  NgModel,
  ReactiveFormsModule,
} from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
  SubscriptSizing,
} from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NoopValueAccessorDirective } from '@rp/shared/directives';
import { injectNgControl } from '@rp/utils';
import { Validation } from '@rp/shared/validators';
import { TranslateModule } from '@ngx-translate/core';

import { IconComponent, IconName } from '../icon';
import { SpinnerComponent } from '../spinner';

@Component({
  selector: 'rp-select',
  standalone: true,
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
  hostDirectives: [NoopValueAccessorDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    NgFor,
    NgxMatSelectSearchModule,
    IconComponent,
    TranslateModule,
    SpinnerComponent,
  ],
  providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } }],
})
export class SelectComponent<T> implements OnInit {
  label = input.required<string>();
  displayField = input<keyof T>();
  hiddenField = input<keyof T>('isHidden' as keyof T);
  selectedField = input<keyof T>();
  items = input.required<T[] | null>();
  icons = input<IconName[]>([]);
  placeholder = input<string>('');
  subscriptSizing = input<SubscriptSizing>('fixed');
  compareObjectWithField = input<keyof T>('id' as keyof T);
  multiple = input(false, {
    transform: booleanAttribute,
  });
  searchable = input(false, {
    transform: booleanAttribute,
  });
  showErrorMessage = input(true, {
    transform: booleanAttribute,
  });
  translateOptions = input(false, {
    transform: booleanAttribute,
  });
  loading = input(false, {
    transform: booleanAttribute,
  });
  showCountryIcons = input(false, {
    transform: booleanAttribute,
  });

  firstError = signal<string>('');
  errors = signal<Validation[]>([]);

  panelClass = computed<string>(() => {
    let panelClass = 'rp-select-panel';

    if (this.searchable()) {
      panelClass = `${panelClass} with-search`;
    }

    if (this.multiple()) {
      panelClass = `${panelClass} with-multiple`;
    }

    return panelClass;
  });

  filteredItems = computed<T[]>(() => {
    let search = this._value().trim();

    if (!this.loading() && this.items()) {
      if (!search) {
        return [...this.items()];
      } else {
        search = search.toLowerCase();

        return this.items().filter(item =>
          item[this.displayField()].toString().toLowerCase().includes(search),
        );
      }
    } else {
      return [];
    }
  });

  ngControl: FormControlDirective | FormControlName | NgModel = injectNgControl();
  filterControl: FormControl<string> = new FormControl<string>('');
  isPrimitiveValue = false;

  private _value = signal<string>('');
  private _destroyRef = inject(DestroyRef);

  ngOnInit(): void {
    this._listenFilterControlChanges();
    this._listenControlStatusChanges();
  }

  onClosed(): void {
    this.filterControl.setValue('');
  }

  compareWithFn = (option: T, value: T): boolean => {
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      return option[this.compareObjectWithField()] === value[this.compareObjectWithField()];
    } else {
      return (
        (typeof option === 'object' ? option[this.compareObjectWithField()] : option) === value
      );
    }
  };

  private _listenFilterControlChanges(): void {
    this.filterControl.valueChanges
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((value: string) => {
        this._value.set(value);
      });
  }

  private _listenControlStatusChanges(): void {
    this.ngControl.control.statusChanges
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((status: FormControlStatus) => {
        if (status === 'INVALID') {
          const { control } = this.ngControl;

          this.errors.set(Object.keys(control.errors ?? {}) as Validation[]);
          this.firstError.set(this.errors().length ? 'validations.' + this.errors()[0] : '');
        }
      });
  }
}
