import { ChangeDetectionStrategy, Component, booleanAttribute, effect, input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { NoopValueAccessorDirective } from '@rp/shared/directives';
import { injectNgControl } from '@rp/utils';

@Component({
  selector: 'rp-toggle',
  standalone: true,
  templateUrl: './toggle.component.html',
  hostDirectives: [NoopValueAccessorDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatSlideToggleModule, ReactiveFormsModule],
})
export class ToggleComponent {
  label = input<string>();
  isDisabled = input(false, {
    transform: booleanAttribute,
  });

  ngControl = injectNgControl();

  constructor() {
    effect(() => {
      const { control } = this.ngControl;

      if (this.isDisabled()) {
        control.disable();
      } else {
        control.enable();
      }
    });
  }
}
