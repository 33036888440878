const stripEmptyFields = <T>(body: T, shouldStripEmptyArrays = false): Partial<T> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const strippedBody: any = { ...body };

  Object.keys(strippedBody).forEach(key => {
    if (strippedBody[key] === null || strippedBody[key] === undefined || strippedBody[key] === '') {
      delete strippedBody[key];
    }

    if (
      shouldStripEmptyArrays &&
      Array.isArray(strippedBody[key]) &&
      strippedBody[key].length === 0
    ) {
      delete strippedBody[key];
    }
  });

  return strippedBody;
};

const isEmptyObject = <T>(object: T): boolean => {
  if (!object) {
    return false;
  }

  return Object.keys(object).length === 0;
};

const sortKeys = <T>(obj: T): T => {
  const entries = Object.entries(obj).sort(([keyA], [keyB]) => keyA.localeCompare(keyB));

  return Object.fromEntries(entries) as T;
};

const objectToQueryString = (params: Record<string, unknown>, arrayKey?: string[]): string => {
  return Object.entries(params)
    .flatMap(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map(item => {
          if (arrayKey?.length) {
            const foundKey = arrayKey.find(k => item[k]);

            return `${key}=${foundKey ? item[foundKey] : item}`;
          }

          return `${key}=${item}`;
        });
      }

      return `${key}=${value}`;
    })
    .join('&');
};

export const ObjectUtils = {
  stripEmptyFields,
  isEmptyObject,
  sortKeys,
  objectToQueryString,
};
