export enum IconName {
  AccountFill = 'account-fill',
  Account = 'account',
  ActivityFill = 'activity-fill',
  Activity = 'activity',
  AddImage = 'add-image',
  Add = 'add',
  AltFill = 'alt-fill',
  Alt = 'alt',
  Apply = 'apply',
  ArrowLeft = 'arrow-left',
  ArrowRight = 'arrow-right',
  Avatar = 'avatar',
  Block = 'block',
  Bnb = 'bnb',
  Btc = 'btc',
  BurgerMenu = 'burger-menu',
  Business = 'business',
  Calculator = 'calculator',
  Calendar = 'calendar',
  Cancel = 'cancel',
  CardFill = 'card-fill',
  Card = 'card',
  Cardano = 'cardano',
  Cardholder = 'cardholder',
  ChevronDown = 'chevron-down',
  ChevronLeft = 'chevron-left',
  ChevronRight = 'chevron-right',
  ChevronUp = 'chevron-up',
  CloseCard = 'close-card',
  CompaniesFill = 'companies-fill',
  Companies = 'companies',
  Company = 'company',
  Copy = 'copy',
  Crypto = 'crypto',
  DarkTheme = 'dark-theme',
  Detail = 'detail',
  Document = 'document',
  Doge = 'doge',
  EditUser = 'edit-user',
  Edit = 'edit',
  Eth = 'eth',
  Exchange = 'exchange',
  Exclusive = 'exclusive',
  Expense = 'expense',
  Export = 'export',
  ExternalCrypto = 'external-crypto',
  External = 'external',
  EyeOff = 'eye-off',
  Eye = 'eye',
  FacebookHover = 'facebook-hover',
  Facebook = 'facebook',
  Faq = 'faq',
  Fees = 'fees',
  Filters = 'filters',
  Finance = 'finance',
  FlagDE = 'flag-de',
  FlagEN = 'flag-en',
  FlagRU = 'flag-ru',
  ForgotPassword = 'forgot-password',
  Hash = 'hash',
  HomeFill = 'home-fill',
  Home = 'home',
  Import = 'import',
  Income = 'income',
  Info = 'info',
  Information = 'information',
  Infrastr = 'infrastr',
  Initials = 'initials',
  InstagramHover = 'instagram-hover',
  Instagram = 'instagram',
  Instruments = 'instruments',
  Internal = 'internal',
  LightTheme = 'light-theme',
  LinkedInHover = 'linkedIn-hover',
  LinkedIn = 'linkedIn',
  Log = 'log',
  Login = 'login',
  Logout = 'logout',
  Mail = 'mail',
  MassPayout = 'mass-payout',
  MenuHide = 'menu-hide',
  Message = 'message',
  MethodsCheck = 'methods-check',
  MyOffersVip = 'my-offers-vip',
  MyOffers = 'my-offers',
  NotPossibleFill = 'not-possible-fill',
  NotificationIndicator = 'notification-indicator',
  Notification = 'notification',
  Offerwall = 'offerwall',
  PossibleFill = 'possible-fill',
  ProfileCircle = 'profile-circle',
  PromoFill = 'promo-fill',
  PromoOutlineFill = 'promo-outline-fill',
  PromoOutline = 'promo-outline',
  Promo = 'promo',
  Rbac = 'rbac',
  Remove = 'remove',
  Reorder = 'reorder',
  ResetFilter = 'reset-filter',
  Reset = 'reset',
  RiskFill = 'risk-fill',
  Risk = 'risk',
  SaveAs = 'save-as',
  Search = 'search',
  SelectWallet = 'select-wallet',
  Send = 'send',
  Settings = 'settings',
  Shop = 'shop',
  Sort = 'sort',
  Statistic = 'statistic',
  SupportFill = 'support-fill',
  SupportOutline = 'support-outline',
  Support = 'support',
  System = 'system',
  TelegramHover = 'telegram-hover',
  Telegram = 'telegram',
  ThreeDs = 'three-ds',
  ThreePoints = 'three-points',
  Timer = 'timer',
  ToFirst = 'to-first',
  ToLast = 'to-last',
  Topup = 'topup',
  Transfer = 'transfer',
  Transition = 'transition',
  Trx = 'trx',
  Twitter = 'twitter',
  TwitterHover = 'twitter-hover',
  Unblock = 'unblock',
  Usdc = 'usdc',
  Usdt = 'usdt',
  UsersFill = 'users-fill',
  Users = 'users',
  WalletFill = 'wallet-fill',
  Wallet = 'wallet',
  Withdrawal = 'withdrawal',
  Xrp = 'xrp',
  Viber = 'viber',
  Skype = 'skype',
  ComingSoon = 'coming-soon',
}

export const ICON_NAMES: IconName[] = Object.values(IconName);
