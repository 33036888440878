@let control = ngControl.control;

<mat-form-field
  class="rp-select-wrapper"
  [class.filled]="!!control.value?.length"
  appearance="outline"
  [subscriptSizing]="subscriptSizing()"
>
  <mat-label>{{ label() }}</mat-label>
  <mat-select
    class="rp-select"
    disableRipple="true"
    [panelClass]="panelClass()"
    [formControl]="control"
    [multiple]="multiple()"
    [placeholder]="placeholder()"
    [compareWith]="compareWithFn"
    (closed)="onClosed()"
  >
    @if (showCountryIcons()) {
      <mat-select-trigger>
        {{ control.value }}
      </mat-select-trigger>
    }
    @if (searchable()) {
      <mat-option>
        <ngx-mat-select-search
          class="rp-select-search"
          [formControl]="filterControl"
          [hideClearSearchButton]="true"
          [placeholderLabel]="'general.placeholders.quickSearch' | translate"
          [noEntriesFoundLabel]="'general.placeholders.noMatch' | translate"
        >
          <div class="mat-select-search-custom-header-content">
            <rp-icon class="search-icon" iconName="search" size="xs" />
          </div>
        </ngx-mat-select-search>
      </mat-option>
    }
    @for (item of filteredItems(); track i; let i = $index) {
      <mat-option [value]="!!selectedField() ? item[selectedField()] : item" [class.hide-option]="!!item[hiddenField()]">
        @if (icons()[i]; as iconName) {
          <rp-icon class="option-icon" [iconName]="iconName" size="xs" />
        }

        @if (item[selectedField()] && showCountryIcons()) {
          <span [class]="'fi fi-' + item[selectedField()].toString().toLowerCase()"></span>
        }

        @if (translateOptions()) {
          {{ $any(item[displayField()]) || item | translate }}
        } @else {
          {{ item[displayField()] || item }}
        }
      </mat-option>
    }
  </mat-select>

  <div class="rp-select-suffix" matSuffix>
    @if (loading()) {
      <rp-spinner type="select" size="2xs" />
    }

    <rp-icon class="chevron-down-icon" iconName="chevron-down" size="s" />
  </div>

  @if (control.invalid && control.touched && errors().length && showErrorMessage()) {
    <mat-error>{{ firstError() | translate }}</mat-error>
  }
</mat-form-field>
