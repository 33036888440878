export type ToastType = 'success' | 'error' | 'info';

export const toastTitleRecord: Record<ToastType, string> = {
  success: 'general.toast.successfully',
  error: 'general.toast.failed',
  info: 'general.toast.info',
};

export const toastIconRecord: Partial<Record<ToastType, string>> = {
  success: 'possible-fill',
  error: 'not-possible-fill',
};
