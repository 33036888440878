import { ChangeDetectionStrategy, Component, booleanAttribute, input, output } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';

import { IconComponent } from '../icon';

@Component({
  selector: 'rp-chip',
  standalone: true,
  templateUrl: './chip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatChipsModule, IconComponent],
})
export class ChipComponent {
  label = input.required<string>();
  isRemovable = input(false, {
    transform: booleanAttribute,
  });
  isDisabled = input(false, {
    transform: booleanAttribute,
  });

  removed = output<void>();

  remove(): void {
    this.removed.emit();
  }
}
