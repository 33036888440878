import { EnvironmentProviders, Injectable, makeEnvironmentProviders } from '@angular/core';
import { DateAdapter, NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  /**
   * Sets Monday to be the first day of week in calendar
   */
  override getFirstDayOfWeek(): number {
    return 1;
  }
}

export const provideCustomDateAdapter = (): EnvironmentProviders =>
  makeEnvironmentProviders([{ provide: DateAdapter, useClass: CustomDateAdapter }]);
