@if (hasRange) {
  <mat-form-field
    [class.filled]="!!startControl.value?.toString().length || !!endControl.value?.toString().length"
    appearance="outline"
    class="rp-date-picker"
  >
    <mat-label>{{ label() }}</mat-label>
    <mat-date-range-input [rangePicker]="picker" [disabled]="isDisabled()">
      <input matStartDate [formControl]="startControl" [placeholder]="placeholderStart()" />
      <input matEndDate [formControl]="endControl" [placeholder]="placeholderEnd()" />
    </mat-date-range-input>
    <mat-hint>{{ hint() }}</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker" [disableRipple]="true">
      <rp-icon class="calendar-icon" iconName="calendar" size="s" matDatepickerToggleIcon />
    </mat-datepicker-toggle>
    <mat-date-range-picker #picker />

    @if (startControl.hasError('required') || endControl.hasError('required')) {
      <mat-error>{{ 'validations.required' | translate }}</mat-error>
    }
  </mat-form-field>
} @else {
  <mat-form-field [class.filled]="!!ngControl.control.value?.toString().length" appearance="outline" class="rp-date-picker">
    <mat-label>{{ label() }}</mat-label>
    <input
      matInput
      [matDatepicker]="picker"
      [formControl]="ngControl.control"
      [errorStateMatcher]="matcher()"
      [placeholder]="placeholder()"
      [disabled]="isDisabled()"
    />
    <mat-hint>{{ hint() }}</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker" [disableRipple]="true">
      <rp-icon class="calendar-icon" iconName="calendar" size="s" matDatepickerToggleIcon />
    </mat-datepicker-toggle>
    <mat-datepicker #picker />

    @if (ngControl.control.hasError('required')) {
      <mat-error>{{ 'validations.required' | translate }}</mat-error>
    }
  </mat-form-field>
}
