import { ObjectUtils } from './object';

const isFilterEqual = <T>(previousFilter: T, currentFilter: T): boolean => {
  const strippedAndSortedFilter = ObjectUtils.sortKeys(ObjectUtils.stripEmptyFields(currentFilter));
  const strippedAndSortedPreviousFilter = ObjectUtils.sortKeys(
    ObjectUtils.stripEmptyFields(previousFilter),
  );

  return (
    JSON.stringify(strippedAndSortedPreviousFilter) === JSON.stringify(strippedAndSortedFilter)
  );
};

export const FilterUtils = {
  isFilterEqual,
};
