import { NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  TemplateRef,
  computed,
  viewChild,
  booleanAttribute,
  signal,
  output,
} from '@angular/core';
import {
  MatPaginator,
  MatPaginatorIntl,
  MatPaginatorModule,
  PageEvent,
} from '@angular/material/paginator';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';

import { PaginationRequest } from '@rp/shared/models';

import { CellMobile } from './model';
import { CustomMatPaginatorIntl } from '../table/intl/custom-paginator.intl';
import { IconComponent } from '../icon';
import { TablePlaceholderComponent } from '../table-placeholder';

@Component({
  selector: 'rp-table-mobile',
  standalone: true,
  templateUrl: './table-mobile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TablePlaceholderComponent,
    MatTableModule,
    MatPaginatorModule,
    NgTemplateOutlet,
    IconComponent,
  ],
  providers: [{ provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl }],
})
export class TableMobileComponent<T> {
  paginator = viewChild(MatPaginator);

  template = input.required<TemplateRef<unknown>>();
  data = input.required<T[]>();
  itemsLength = input.required<number>();
  pageSizeOptions = input<number[]>([10, 20, 30]);
  showFirstLastButtons = input(true, {
    transform: booleanAttribute,
  });
  hidePageSize = input(true, {
    transform: booleanAttribute,
  });

  pagination = output<PaginationRequest>();

  pageSize = signal<number>(10);
  pageIndex = signal<number>(0);

  dataSource = computed(
    () => new MatTableDataSource<CellMobile<T>>(this.data().map(data => ({ cell: data }))),
  );

  displayedColumns: string[] = ['cell'];

  onPageChange(pageEvent: PageEvent): void {
    this.pageSize.set(pageEvent.pageSize);
    this.pageIndex.set(pageEvent.pageIndex);
    this.pagination.emit({
      page: pageEvent.pageIndex + 1,
      limit: pageEvent.pageSize,
    });
  }

  resetPaginator(): void {
    this.pageSize.set(10);
    this.pageIndex.set(0);
  }
}
