import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { EmailPattern } from '@rp/shared/models';

import { Validation } from './enums/validation.enum';

export const emailPatternValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const valid = EmailPattern.test(control.value);

  return valid ? null : { [Validation.InvalidEmail]: true };
};
