import { inject, Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { LANGUAGE_KEY, Languages } from '@rp/shared/models';

import { LocalStorageService } from './local-storage.service';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  private _translateService = inject(TranslateService);
  private _localStorageService = inject(LocalStorageService);

  getCurrentLanguage(): Languages {
    return (this._localStorageService.getValue(LANGUAGE_KEY) ||
      this._translateService.defaultLang) as Languages;
  }

  setLanguage(language: Languages): void {
    this._translateService.use(language);
    this._localStorageService.setValue(LANGUAGE_KEY, language);
  }
}
