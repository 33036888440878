import { Languages } from '@rp/shared/models';

import { EnvironmentModel } from '../../models/environment.model';

export const environment: EnvironmentModel = {
  production: false,
  uiConfig: {
    header: {
      shouldShowMyBalance: false,
      haveAccountSettings: false,
      havePersonalManager: false,
    },
    sidebar: ['finance', 'adminStatistic', 'partners', 'offersWall', 'rbac'],
  },
  apiConfig: {
    adminApiHost: 'https://admin-gateway.rpartners-dev.com/api/v1',
    mockToken:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoyMzI2NTcxMjQ1LCJpYXQiOjE3MjY1NzEyNDUsImp0aSI6ImEwMjQ4MmIwYzA1MzQ5NGRiMzBkNjM3OThiOTMxZmRmIiwidXNlcl9pZCI6MTksIm5hbWUiOiJ0ZXN0X25hbWUiLCJlbWFpbCI6ImFkbWluMUBhZG1pbi5jb20iLCJ1c2VyX3R5cGUiOiJhZG1pbiJ9.nExg-0v4nUFeW-vKoqDepF5gPYPSnGWtA5iitljkjtg',
  },
  defaultLanguage: Languages.EN,
  languages: [Languages.EN, Languages.RU],
};
